<template>
  <section
    :id="slice.primary.reference"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="clamped"
  >
    <PrismicRichText v-if="slice.primary.header" :field="slice.primary.header" class="header" />

    <div class="card-list">
      <div v-for="(item, index) in slice.items" :key="index" class="card" role="button" @click="e => goToCard(e, item)">
        <div class="feature">
          <ResizedImage
            v-if="item.thumbnail.url || item.page_link.data"
            :field="item.thumbnail.url ? item.thumbnail : item.page_link.data.image"
            height="310"
            width="310"
            html-height="210"
            html-width="210"
            class="image"
            loading="lazy"
          />
        </div>
        <div class="content">
          <PrismicRichText v-if="item.sub_heading > 0" :field="item.sub_heading" class="cms-subheading" />
          <h4 v-else-if="item.page_link.data" class="subheading">
            {{ item.page_link.data.subtitle }}
          </h4>

          <PrismicRichText v-if="item.heading.length > 0" :field="item.heading" class="cms-heading" />
          <h2 v-else class="heading">
            {{ item.page_link.data.title }}
          </h2>

          <PrismicRichText
            v-if="item.description.length > 0 || item.page_link.data"
            class="copy"
            :field="item.description.length > 0 ? item.description : item.page_link.data.summary"
          />

          <NuxtLink v-if="item.primary_link.url || item.page_link.url" :to="item.primary_link.url || item.page_link.url" class="link">
            {{ item.primary_link_text || 'View more' }}
          </NuxtLink>
          <NuxtLink v-else-if="item.primary_link.type === 'product_feature'" :to="'/product/' + item.primary_link.slug" class="link">
            {{ item.primary_link_text || 'View more' }}
          </NuxtLink>

          <span v-if="item.secondary_link.url">
            <span class="separator">or</span>
            <NuxtLink :to="item.secondary_link.url" class="link">{{ item.secondary_link_text || 'View more' }}</NuxtLink>
          </span>
          <span v-else-if="item.secondary_link.type === 'product_feature'">
            <span class="separator">or</span>

            <NuxtLink :to="'/product/' + item.secondary_link.slug" class="link">{{ item.secondary_link_text || 'View more' }}</NuxtLink>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';
import ResizedImage from '~/components/General/ResizedImage';

export default {
  name: 'CardList',
  components: { ResizedImage },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  methods: {
    goToCard(evt, item) {
      if (evt.target.nodeName === 'A') {
        return false;
      }

      if (item.primary_link.url) {
        this.$router.push(item.primary_link.url);
      }

      if (item.page_link.url) {
        this.$router.push(item.page_link.url);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$orientation-bp: 580px;

section {
  margin-block: 1em 2em;
  margin-top: 1rem;
  max-width: calc(1024px + 4rem);
}

.card-list {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.card {
  border: 2px solid var(--black);
  border-radius: 32px;
  padding: min(1.75em, 0.75em + 2vmin);
  display: flex;
  align-items: center;
  gap: 2em;
  background-color: rgba(var(--card-color), 0.1);
  cursor: pointer;
  transition: box-shadow 100ms ease-in-out;

  @include for-mobile(900px) {
    font-size: 0.8em;
  }

  @media screen and (max-width: $orientation-bp) {
    flex-direction: column;
  }
}

.card:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.card:nth-child(4n) {
  --card-color: var(--color-green-rgb);
}

.card:nth-child(4n+1) {
  --card-color: var(--color-pink-rgb);
}

.card:nth-child(4n+2) {
  --card-color: var(--color-yellow-rgb);
}

.card:nth-child(4n+3) {
  --card-color: var(--color-blue-rgb);
}

.feature {
  flex: 0 0 210px;
  background-color: rgb(var(--card-color));
  aspect-ratio: 1 / 1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;

  @media screen and (max-width: $orientation-bp) {
    aspect-ratio: 2 / 1;
  }
}

.image {
  max-width: 100%;
}

.subheading,
.cms-subheading::v-deep > :is(h3, h4, h5, h6) {
  margin: 0 0 0.5em;
  font-family: var(--font-family--secondary);
  font-weight: normal;
}

.heading,
.cms-heading::v-deep > h2 {
  line-height: 1.2;
  font-size: min(1vmin + 1em, var(--h2-font-size));
}

.link {
  color: var(--black);
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: bold;
}

.link:hover {
  color: var(--c-link);
}

.separator {
  padding-inline: 0.5em;
}
</style>
